<template>
  <div class="fill-height">
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container style="height: 100%">
      <v-row justify="center" style="height: 100%">
        <v-col cols="12">
          <v-card
            :loading="loading"
            scrollable
            style="height: 100%"
            class="grow d-flex flex-column flex-nowrap"
          >
            <v-card-title>
              <v-row class="ml-0 mr-0 pt-3 image_filter_header">
                <span class="gray_color mr-3 mt-3">
                  <h5 style="font-size: 12px; font-weight: 300">Results:</h5>
                  <h4 style="font-size: 18px; font-weight: 400">
                    {{ image_count }}
                  </h4>
                </span>
                <div class="d-flex">
                  <v-icon color="primary" class="mr-2" @click="selectAllIcon">{{
                    check_all_icon
                  }}</v-icon>
                  <v-icon
                    class="mr-2"
                    @click="show_hover = !show_hover"
                    :color="show_hover ? 'primary' : null"
                    :disabled="loading"
                    dense
                  >
                    mdi-cursor-default
                  </v-icon>
                  <v-icon
                    color="primary"
                    class="mr-2"
                    :disabled="selected_images.length <= 0 || loading"
                    @click="downloadAllFiles('json')"
                    >mdi-download</v-icon
                  >
                  <v-icon
                    color="primary"
                    :disabled="selected_images.length <= 0 || loading"
                    @click="downloadAllFiles('image')"
                    >mdi-image-area-close</v-icon
                  >
                </div>
                <v-col sm="6" md="2">
                  <v-btn block @click="openImageFilters">Video Filter</v-btn>
                </v-col>
                <v-col sm="6" md="2">
                  <v-menu
                    v-model="datetime_menu"
                    :close-on-content-click="false"
                    open-on-hover
                    offset-overflow
                    :disabled="loading"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="filter_button mr-4"
                        :disabled="loading"
                      >
                        <span v-if="!selected_dates.length">all dates</span>

                        <span v-if="selected_dates.length == 1">{{
                          selected_dates[0]
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/")
                        }}</span>
                        <span v-if="selected_dates.length == 2">{{
                          selected_dates[0]
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/") +
                          " - " +
                          selected_dates[1]
                            .split(" ")[0]
                            .replace("-", ":")
                            .split(":")[1]
                            .replaceAll("-", "/")
                        }}</span>
                      </v-btn>
                    </template>

                    <v-card height="380" width="280">
                      <v-row>
                        <v-col>
                          <v-date-picker
                            v-model="date_range"
                            flat
                            range
                            no-title
                            id="video_dp"
                            event-color="#ff9900"
                            :max="todays_date"
                          ></v-date-picker>
                        </v-col>
                      </v-row>
                      <v-row v-if="show_timebar">
                        <v-btn
                          class="filter_button ml-6"
                          color="primary"
                          id="saveButton"
                          @click="saveDTFilterMenu"
                        >
                          Save
                        </v-btn>
                        <v-icon
                          v-if="selected_dates.length"
                          @click="reset_dates"
                          class="ml-4 mt-n2"
                          >mdi-filter-off-outline</v-icon
                        >
                      </v-row>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="3" md="1">
                  <v-select
                    v-model="per_row"
                    :items="row_limits"
                    label="Per Row"
                    dense
                    hide-details
                    :disabled="loading"
                  ></v-select>
                </v-col>
                <v-col sm="3" md="1">
                  <v-select
                    v-model="per_page"
                    :items="page_limits"
                    label="Per Page"
                    dense
                    hide-details
                    :disabled="loading"
                  ></v-select>
                </v-col>
                <v-col sm="6" md="2" class="mt-n4 mr-n3">
                  <CameraAutoComplete
                    :all="true"
                    v-model="selected_camera"
                    :owner="selected_customer"
                    :disabled="loading"
                  ></CameraAutoComplete>
                </v-col>
                <v-col sm="6" md="2" class="mt-n4">
                  <customer-filter
                    v-model="selected_customer"
                    :disabled="loading"
                  ></customer-filter>
                </v-col>
                <div class="d-flex mt-n1">
                  <v-icon @click="refresh" :disabled="loading"
                    >mdi-refresh</v-icon
                  >
                  <v-icon
                    @click="show_bbox = !show_bbox"
                    v-if="!show_bbox"
                    :disabled="loading"
                    >mdi-drawing-box</v-icon
                  >
                  <!-- <v-icon @click="show_bbox = !show_bbox" v-else color="primary" :disabled="loading"
                  >mdi-drawing-box</v-icon
                >
                <v-icon @click="set_borders(true)" v-if="!bordered" :disabled="loading"
                  >mdi-border-all-variant</v-icon
                > -->
                  <v-icon
                    @click="set_borders(true)"
                    v-else
                    color="primary"
                    :disabled="loading"
                    >mdi-border-all-variant</v-icon
                  >
                  <!-- <v-icon @click="show_text = !show_text" v-if="!show_text" :disabled="loading"
                  >mdi-format-text</v-icon
                >
                <v-icon @click="show_text = !show_text" v-else color="primary" :disabled="loading"
                  >mdi-format-text</v-icon
                > -->
                </div>
              </v-row>
            </v-card-title>
            <!-- image list -->
            <v-card-text
              style="height: 700px; overflow-y: auto; overflow-x: hidden"
            >
              <!-- <v-btn v-if="inital_fetch" class="center" @click="inital_fetch=false; fetchVideos(true);">Load Videos</v-btn> -->
              <v-row class="ml-0 mr-0 pb-3">
                <div id="grid-container-lf">
                  <VideoBoardThumbnail
                    v-for="(p, index) in video_paths"
                    :key="p.image_path + '_' + index"
                    :vid="p"
                    :vid_index="index"
                    :show_text="show_text"
                    :show_hover="show_hover"
                    :bordered="bordered"
                    :filters="filters"
                    @select="toggle_select"
                  ></VideoBoardThumbnail>
                </div>
                <div class="skeleton_container">
                  <v-skeleton-loader
                    v-if="!!video_next_token"
                    v-intersect.quiet="nextPage"
                    type="list-item-three-line"
                  ></v-skeleton-loader>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="video_player_dialog"
      :max-width="is_json && showSideContainers ? `1440px` : '1000px'"
    >
      <v-card style="min-width: 1000px">
        <v-card-title v-if="video_paths[selected_index]">
          <div id="full_size_ct_div">
            <span
              ><b
                class="footage_link"
                v-if="video_paths[selected_index].camera_id"
                >OneCup Ranching</b
              ></span
            >
            <span>
              <b
                class="footage_link"
                v-if="video_paths[selected_index].camera_id"
                >{{ video_paths[selected_index].camera_id }}</b
              >
            </span>
            <span class="nav_arrows_f">
              <v-tooltip color="black" bottom v-if="json_file_not_present">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      v-model="is_json"
                      label="BETSY"
                      hide-details
                      inset
                      :disabled="json_file_not_present"
                      class="mt-1"
                    >
                    </v-switch>
                  </div>
                </template>
                <div>
                  BETSY Vision is currently unavailable for this video. Please
                  try again later.
                </div>
              </v-tooltip>
              <v-switch
                v-if="!json_file_not_present"
                v-model="is_json"
                label="BETSY"
                hide-details
                inset
                :disabled="!json_loading"
                :loading="json_loading ? 'false' : 'primary'"
                class="mt-1"
              >
              </v-switch>
              <span class="filter_heading">
                <v-icon
                  large
                  class="mt-n1"
                  @click="select_video(selected_index - 1)"
                  v-if="selected_index > 0"
                  >mdi-chevron-left</v-icon
                >
                <v-icon large class="mt-n1" v-else disabled
                  >mdi-chevron-left</v-icon
                >
                <v-icon
                  large
                  class="mt-n1"
                  @click="select_video(selected_index + 1)"
                  v-if="selected_index < video_paths.length - 1"
                  >mdi-chevron-right</v-icon
                >
                <v-icon large class="mt-n1" v-else disabled
                  >mdi-chevron-right</v-icon
                ></span
              >
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <video-player
            preload
            class="vjs-big-play-centered"
            :is_json="is_json"
            :json_file_not_present="json_file_not_present"
            :videoFps="videoFps"
            :portal="portal"
            :json_loading="json_loading"
            :camera_model="camera_model"
            :customerFirstName="customerFirstName"
            :customerLastName="customerLastName"
            :customer_id="customer_id"
            :navigation_button_change="navigation_button_change"
            :videoPlayerDialog="video_player_dialog"
            @update-loading="updateJsonLoading"
            :json_url="json_file_url"
            :options="{
              autoplay: true,
              controls: false,
              fluid: true,
            }"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <general-filter-card></general-filter-card>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
// import VueLoadImage from "vue-load-image";
import moment from "moment";
import CustomerFilter from "../components/CustomerFilter.vue";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";
// import ImageCard from "../components/ImageCard.vue";
import VideoBoardThumbnail from "../components/VideoBoardThumbnail.vue";
import { eventBus } from "../main";
import JSZip from "jszip";
import GeneralFilterCard from "../components/GeneralFilterCard.vue";
import { createGeneralFilter } from "../utilities/GeneralFilterTemplate";
import VideoPlayer from "@/components/video_player/VideoPlayer.vue";
import videojs from "video.js";
import Vue from "vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    VideoPlayer,
    // "vue-load-image": VueLoadImage,
    CustomerFilter,
    // ImageCard,
    VideoBoardThumbnail,
    GeneralFilterCard,
    CameraAutoComplete,
  },
  data() {
    return {
      loading: false,
      per_page: 64,
      page_limits: [48, 64, 80, 96, 144, 196, 256, 512, 1000],
      per_row: 6,
      row_limits: [2, 3, 4, 5, 6, 7, 8, 10, 12, 25, 50],
      show_text: false,
      show_bbox: true,
      show_hover: false,
      showSideContainers: window.innerWidth >= 1465,

      selected_customer: "All Customers",
      bordered: false,
      filters: createGeneralFilter(this.$store.getters),
      Contraction_count: 0,
      Contraction_Classification_count: 0,
      Activity_count: 0,
      Age_count: 0,
      Suckling_count: 0,
      video_next_token: null,
      todays_date: moment().format("YYYY-MM-DD"),
      camera_model: "",
      customerFirstName: "",
      customerLastName: "",

      // model versions
      model_versions: {},

      selected_images: [],
      check_all_icon: "mdi-checkbox-multiple-blank-outline",

      date_range: [],
      datetime_menu: false,
      selected_camera: null,
      inital_fetch: true,
      selected_dates: [],
      show_timebar: false,

      video_player_dialog: false,
      is_json: false,
      selected_index: null,
      json_file_not_present: false,
      videoFps: 0,
      portal: "admin",
      json_loading: false,
      navigation_button_change: false,
      timeStamp: "",
      customer_id: "",
      json_file_url: "",
    };
  },
  methods: {
    updateJsonLoading(newValue) {
      this.json_loading = newValue;
    },
    checkScreenWidth() {
      this.showSideContainers = window.innerWidth >= 1465;
    },
    open_dialog(video_index) {
      this.select_video(video_index);
    },
    async jsonPresent() {
      const url = this.video_paths[this.selected_index].video_path;
      this.json_file_not_present = false;

      const json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

      try {
        const response = await fetch(json_file_source, {
          credentials: "include",
        });
        this.is_json = false;
        if (!response.ok) {
          this.json_file_not_present = true;
          this.json_data = "";
          return;
        } else {
          this.is_json = true;
          this.json_file_not_present = false;
        }
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    },
    select_video(index) {
      this.selected_index = index;
      this.video_player_dialog = true;
      this.navigation_button_change = !this.navigation_button_change;
      try {
        this.json_file_url = this.video_paths[this.selected_index].video_path;
        this.jsonPresent();
        this.playVideo();
      } catch {
        console.log("err");
      }
    },
    playVideo() {
      this.playHLS();
    },

    async playHLS() {
      const videoElement = document.querySelector(".video-js");
      if (!videoElement) {
        //console.log('Video element with class "video-js" not found.');
        return;
      }

      var player = videojs(videoElement);

      const url = this.video_paths[this.selected_index].video_path;
      this.videoFps = this.video_paths[this.selected_index]["fps"];
      this.timeStamp = this.video_paths[this.selected_index].video_time;
      this.customer_id = this.video_paths[this.selected_index].customer_id;
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";

      eventBus.$emit("sourceChanged", source);
      var res = await fetch(source, { credentials: "include" });
      if (res.status == 403) {
        var arr = url.replaceAll("+", "/").split("/");
        arr.splice(1, 0, "ftp");
        console.log(arr);
        source = process.env.VUE_APP_COOKIE_BUCKET + arr.join("/") + ".m3u8";
        res = await fetch(source, { credentials: "include" });
      }
      const tn = await res.text();
      const tn_arr = tn.split(/#EXTINF:/);
      tn_arr.shift();
      tn_arr.pop();
      // tn_arr.pop();
      // const duration_split =
      //   this.video_paths[this.selected_index].video_duration.split(":");

      // const duration_sec =
      //   (parseInt(duration_split[0]) * 60 + parseInt(duration_split[1])) /
      //   tn_arr.length;

      const duration_sec =
        this.video_paths[this.selected_index]["num_frames"] /
        this.video_paths[this.selected_index]["fps"];

      var pathsArray = [];
      var v_tn = {};
      for (const i in tn_arr) {
        const key =
          parseInt(tn_arr[i].slice(-8, -4).replace("-", ""), 10) * duration_sec;

        const path =
          process.env.VUE_APP_COOKIE_BUCKET +
          url.replaceAll("+", "/").slice(0, url.lastIndexOf("/") + 1) +
          "hls/" +
          tn_arr[i].split("\n")[1].replace(".ts", "") +
          ".jpg";

        v_tn[key] = { src: path };
        pathsArray.push(path);
      }

      eventBus.$emit("thumbnailPicArray", v_tn);

      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
      //this.fetchJSON();
    },
    reset_dates() {
      this.selected_dates = [];
      this.date_range = [];
      this.fetchVideos(true);
    },
    saveDTFilterMenu() {
      this.datetime_menu = false;
      this.selected_dates = this.date_range;
      this.selected_dates.sort();
      this.fetchVideos(true);
    },
    openImageFilters() {
      var params = {
        filters: this.filters,
        date_range: this.date_range,
        model_versions: this.model_versions,
      };
      eventBus.$emit("open_dialog_filters", params);
    },
    selectAllIcon() {
      if (this.check_all_icon != "mdi-checkbox-multiple-blank-outline") {
        this.check_all_icon = "mdi-checkbox-multiple-blank-outline";
      } else {
        this.check_all_icon = "mdi-checkbox-multiple-marked";
      }
      var has_selected_all =
        this.selected_images.length == this.video_paths.length;
      eventBus.$emit("select_all_images", has_selected_all);
    },
    async downloadAllFiles(type) {
      this.loading = true;

      var zip = JSZip();
      var zip_promises = [];

      for (let i = 0; i < this.selected_images.length; i++) {
        if (type != "image") {
          zip_promises.push(this.fetchJSON(zip, this.selected_images[i]));
        }
        zip_promises.push(this.fetchImage(zip, this.selected_images[i]));
      }

      await Promise.all(zip_promises);
      zip.generateAsync({ type: "blob" }).then(
        (blob) => {
          this.downloadBlob(blob, "Export.zip");
          this.loading = false;
        },
        (err) => {
          console.log(err);
          this.loading = false;
        }
      );
    },
    fetchJSON(zip, image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + ".json.zip";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.loadAsync(data);
          })
          .then((zip) => {
            resolve(zip);
          });
      });
    },
    fetchImage(zip, image_path) {
      var url = process.env.VUE_APP_COOKIE_BUCKET + image_path + "_event.jpg";
      return new Promise((resolve) => {
        fetch(url, { credentials: "include" })
          .then((res) => {
            return res.blob();
          })
          .then((data) => {
            return zip.file(image_path.split("/").pop() + "_event.jpg", data);
          })
          .then((zip) => {
            resolve(zip);
          });
      });
    },
    downloadBlob(blob, filename) {
      var blobUrl = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    toggle_select(image_path) {
      var index = this.selected_images.indexOf(image_path);
      if (index >= 0) {
        this.selected_images.splice(index, 1);
      } else {
        this.selected_images.push(image_path);
      }
    },
    // open_image(index) {
    //   var params = {
    //     image_path: this.video_paths[index].image_path,
    //     image_time: this.video_paths[index].image_time,
    //     camera_id: this.video_paths[index].camera_id,
    //     selected: !!this.selected_images.find(e=>e==this.video_paths[index].image_path)
    //   };
    //   eventBus.$emit("open_dialog_images", params);
    // },
    initializeDataRange() {
      var date = moment().utc();
      var now = date.format("YYYY-MM-DD");
      // var past = date.subtract(1, "day").format("YYYY-MM-DD");
      this.date_range = [now];
      this.selected_dates = this.date_range;
    },
    refresh() {
      this.inital_fetch = false;
      this.fetchVideos(true);
    },
    fetchVideos(first_time) {
      first_time;
      if (this.inital_fetch) {
        return;
      }
      this.loading = true;
      if (first_time) {
        this.selected_images = [];
      }
      var selected_dates = [...this.selected_dates];
      var params = {
        limit: this.per_page,
        first_time: first_time,
        date_range: selected_dates.sort(),
        filters: this.filters,
        model_versions: this.model_versions,
        sort_fields: [
          {
            field: "time",
            direction: "DESC",
          },
          {
            field: "camera_id",
            direction: "DESC",
          },
        ],
      };
      if (this.selected_customer && this.selected_customer != "All Customers") {
        params.user_id = this.selected_customer;
      }

      if (this.selected_camera) {
        params.camera_id = this.selected_camera;
      }
      this.$store
        .dispatch("DDB_GET_VIDEOS_VIDEOBOARD", params)
        .then((nextToken) => {
          this.video_next_token = nextToken;
          this.loading = false;
        });
    },
    age(date) {
      if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "seconds"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "seconds"
          ) +
            " " +
            "s",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "minutes"
        ) < 60
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "minutes"
          ) +
            " " +
            "m",
          this.bordered ? "lf_green_active" : "lf_green",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "hours"
        ) < 24
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "hours"
          ) +
            " " +
            "h",
          this.bordered ? "lf_blue_active" : "lf_blue",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "days"
        ) < 30
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "days"
          ) +
            " " +
            "D",
          this.bordered ? "lf_yellow_active" : "lf_yellow",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "months"
        ) < 12
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "months"
          ) +
            " " +
            "M",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else if (
        moment().diff(
          moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
          "years"
        ) < 365
      ) {
        return [
          moment().diff(
            moment(date, "YYYY-MM-DD hh:mm:ss").utcOffset(0, true),
            "years"
          ) +
            " " +
            "Y",
          this.bordered ? "lf_orange_active" : "lf_orange",
        ];
      } else {
        return [false, false];
      }
    },
    nextPage(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchVideos(false);
      }
    },
    set_borders(upd_bor) {
      if (upd_bor) {
        this.bordered = !this.bordered;
      }
      if (!this.bordered) {
        document.querySelectorAll(".lf_green_active").forEach(function (cl) {
          cl.classList.replace("lf_green_active", "lf_green");
        });
        document.querySelectorAll(".lf_blue_active").forEach(function (cl) {
          cl.classList.replace("lf_blue_active", "lf_blue");
        });
        document.querySelectorAll(".lf_yellow_active").forEach(function (cl) {
          cl.classList.replace("lf_yellow_active", "lf_yellow");
        });
        document.querySelectorAll(".lf_orange_active").forEach(function (cl) {
          cl.classList.replace("lf_orange_active", "lf_orange");
        });
      } else {
        document.querySelectorAll(".lf_green").forEach(function (cl) {
          cl.classList.replace("lf_green", "lf_green_active");
        });
        document.querySelectorAll(".lf_blue").forEach(function (cl) {
          cl.classList.replace("lf_blue", "lf_blue_active");
        });
        document.querySelectorAll(".lf_yellow").forEach(function (cl) {
          cl.classList.replace("lf_yellow", "lf_yellow_active");
        });
        document.querySelectorAll(".lf_orange").forEach(function (cl) {
          cl.classList.replace("lf_orange", "lf_orange_active");
        });
      }
    },
    updatePerRow() {
      var container = document.getElementById("grid-container-lf");
      container.style["grid-template-columns"] = `repeat(${this.per_row}, 1fr)`;
    },
    findDuplicates(array) {
      let resultToReturn = false;
      // call some function with callback function as argument
      resultToReturn = array.filter((element, index) => {
        var first_index = array.findIndex(
          (e) => e.image_path == element.image_path
        );
        if (first_index !== index) {
          console.log(`Duplicate Images: ${first_index} and ${index}`);
          return true;
        }

        return false;
      });
      // console.log(resultToReturn);
      if (resultToReturn) {
        console.log("Duplicate elements exist");
      } else {
        console.log("Duplicates don't exist ");
      }
    },
  },
  watch: {
    // selected_index() {
    //   if (this.video_player_dialog) {
    //     console.log("this runs")
    //     try {
    //       // this.fetchJSON();
    //       this.playVideo();
    //     } catch {
    //       console.log("err");
    //     }
    //   }
    // },

    per_row() {
      this.updatePerRow();
      if ((this.per_row == 10 || this.per_row == 12) && this.per_page < 144) {
        this.per_page = 144;
      } else if (
        (this.per_row == 25 || this.per_row == 50) &&
        this.per_page < 1000
      ) {
        this.per_page = 1000;
      }
    },
    per_page() {
      this.fetchVideos(true);
    },
    selected_customer() {
      this.fetchVideos(true);
    },
    selected_camera() {
      this.fetchVideos(true);
    },
    date_range() {
      if (this.date_range.length > 0) {
        this.show_timebar = true;
      } else {
        this.show_timebar = false;
      }
    },
    // model_versions: {
    //   handler() {
    //     this.fetchVideos(true);
    //   },
    //   deep: true,
    // },
    // filters: {
    //   handler() {
    //     console.log(this.filters);
    //     this.fetchVideos(true);
    //   },
    //   deep: true,
    // }
  },
  computed: {
    // date_range_text() {
    //   var temp_arr = [...this.date_range];
    //   return temp_arr.sort().join(" ~ ");
    // },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    video_paths() {
      if (this.$store.getters.getVideosVideoBoard[0]) {
        return this.$store.getters.getVideosVideoBoard[0];
      } else {
        return [];
      }
    },
    image_count() {
      if (this.$store.getters.getVideosVideoBoard[2]) {
        return this.$store.getters.getVideosVideoBoard[2].toLocaleString();
      } else {
        return 0;
      }
    },
    PGIE_type_list() {
      return this.$store.getters.getPGIETypeList;
    },
    Species_type_list() {
      return this.$store.getters.getSpeciesTypeList;
    },
    SGIE_type_list() {
      return this.$store.getters.getSGIETypeList;
    },
    model_version_list() {
      return this.$store.getters.getModelVersionList;
    },
  },
  created() {
    this.initializeDataRange();
    this.inital_fetch = false;
    this.fetchVideos(true);
    eventBus.$on("player_ready", () => {
      this.playVideo();
    });
    // this.fetchVideos(true);
    eventBus.$on("open_dialog", (video_index) => {
      this.open_dialog(video_index);
    });

    eventBus.$on("update_filters", (params) => {
      this.filters = params.filters;
      this.date_range = params.date_range;
      this.model_versions = params.model_versions;
      this.fetchVideos(true);
    });
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener("resize", this.checkScreenWidth);
  },
};
</script>
<style lang="css" scoped>
.filter_button {
  font-size: 14px;
  text-transform: none;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#lf_btns {
  /* width: 75px !important; */
  display: flex;
  float: right;
  margin-right: 6px;
  margin-top: 5px;
}
.image_filter_header {
  line-height: 16px;
}
.skeleton_container {
  width: 100%;
}
.lf_green_active {
  border: 2px solid green;
}
.lf_blue_active {
  border: 2px solid rgb(0, 0, 220);
}
.lf_yellow_active {
  border: 2px solid rgb(220, 220, 0);
}
.lf_orange_active {
  border: 2px solid orange;
}
#grid-container-lf {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}
.grid_container_f {
  position: relative;
  text-align: center;
  /* min-height: 130px; */
}
/* .grid_container_f:hover {
  transform: scale(1.3);
  box-shadow: 0 0 10px black;
  z-index: 2 !important;
} */
.grid_container_f:hover > .datetime_grid {
  z-index: 3 !important;
}
.grid_img_f {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.darker_bckrnd {
  /* background-color: rgba(0, 0, 0, 0.377); */
  padding-left: 1px;
  padding-right: 1px;
}
.datetime_grid {
  top: 0px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 12px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cam_id_grid {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ago_grid {
  text-align: left;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-size: 11px;
  width: 95%;
  user-select: none;
  /* background-color: rgba(0, 0, 0, 0.377); */
}
.user_id_grid {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #ffffff;
  font-weight: bolder;
  font-size: 9px;
  width: 95%;
  text-align: left;
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image_filter_menu {
  z-index: 101 !important;
}
.nav_arrows_f {
  float: right;
  width: 180px;
  display: flex;
}
#full_size_ct_div {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
#full_size_ct_div span {
  color: #0090a4;
}
</style>
